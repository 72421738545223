// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

.v-btn {
    text-transform: capitalize !important;
}

.custom-outlined-card {
    border-color: #9e9e9e !important;
    /* Change this color to your desired color */
}